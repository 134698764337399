import Config from "../Config";

const ITEM_PRECISION_ACCURACY = Config.ITEM_PRECISION_ACCURACY;

export const isLowPrecision = (precision) => {
  return precision <= ITEM_PRECISION_ACCURACY;
};

export const formatMoneyBRL = (valor) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(valor);
}

export const getQueryParam = (param) => {
  const search = window.location.hash.includes("?") ? window.location.hash.split("?")[1] : '';
  const urlParams = new URLSearchParams(search);
  return urlParams.get(param);
}

export const getContrast = (hex) => {
  const hexToRgb = (hex) => {
    if (Array.isArray(hex)) {
      return hex;
    }

    hex = hex.replace(/^#/, '');

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  };

  if (!hex) return undefined;

  const rgb = hexToRgb(hex);
  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;

  return brightness > 125 ? 'black' : 'white';
};

export const ORDER_STATUS = {
  CREATED: 'created',
  VIEWED: 'viewed',
  REVISED: 'revised',
  CANCELED: 'canceled',
  CLIENT_NOT_FOUND: 'client_not_found',
  SENT_TO_DEVICE: 'sent_to_device',
  INVALID_CONTENT: 'invalid_content'
}
