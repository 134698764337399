import React, { useCallback, useState } from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { CloseOutlined, DeleteFilled } from '@ant-design/icons'
import { Alert, Button, Input, List, Spin, Tag, Checkbox, message, Modal } from 'antd'
import { useRecoilState } from 'recoil'
import moment from 'moment'
import _ from 'lodash'
import { formatBRL } from './../../Util/Number'
import ModalChooseCustomer from "../ModalChooseCustomer";
import { getContrast, ORDER_STATUS } from "../../Util";
import { TouchableOpacity } from "react-native-web";
import ExtraInfoCustomerNotFound from "../ExtraInfoCustomerNotFound";
import profileState from '../../Stores/Atoms/ProfileAtom'
import { cancelMultiple } from '../../Services/Order'
import { withRouter } from 'react-router-dom';

const IconText = ({ type, text, color }) => (
  <span>
    <LegacyIcon type={type} style={{ marginRight: 8, color: color }} />
    {text}
  </span>
)

const OrdersList = ({
  loading,
  onClickOrder,
  onSavingOrderNumber,
  data,
  showModal,
  onRefreshList,
  propsMultiCancel = {},
  configAlertPrecision = {},
  history
}) => {
  const {
    showMultiCancel = false, 
    multiCancel = false, 
    onClickSelectOrders = () => {},
    onCheckOrder = () => {},
    checkedOrders = {},
    countCheckedOrders = 0,
    allIsChecked = false,
    onCheckAllOrders = () => {},
    onSuccessCancelMulti = () => {},
    beforeCancelMulti = () => {},
    onFinnalyCancelMulti = () => {},
    loadingCancelOrders = false,
    hideConfirmButton = false,
    marginList = 65,
  } = propsMultiCancel;

  const { showButton: showButtonAlertPrecision = true, showAlert: showAlertPrecision = true } = configAlertPrecision || {};

  const [showModalChooseCustomer, setShowModalChooseCustomer] = useState(false);
  const [orderActive, setOrderActive] = useState(null);
  const profile = useRecoilState(profileState);

  const getColor = (item) => {
    if (item.status === 'revised') {
      if (item.order_number) {
        return '#87d068'
      } else if (item.temp_order_number) {
        return '#fa8c16'
      } else {
        return '#f5cd4e'
      }
    } else if (item.status === 'sent_to_device') {
      return '#87d068'
    } else if (item.status === 'canceled') {
      return '#ea0303'
    } else if (item.attempt_quantity_export) {
      return '#f5cd4e'
    } else if (item.status === ORDER_STATUS.INVALID_CONTENT) {
      return '#d46b08';
    } else if (isLowPrecisionPDF(item)) {
      return '#e6da00';
    }

    return '#2db7f5'
  }

  function getIsPeddingOrder(status = '') {
    const notStatus = ['revised', 'canceled', 'sent_to_device', ORDER_STATUS.INVALID_CONTENT];

    if (notStatus.includes(status)) {
      return false; 
    }

    return true;
  }

  function isLowPrecisionPDF(item = {}) {
    const isPeddingOrder = getIsPeddingOrder(item.status);
    const informacao_cliente = _.get(item, 'customer_info');
    return isPeddingOrder && informacao_cliente;
  } 

  const getStatusLabel = (item) => {

    if (item.status === 'revised') {
      if (!item.temp_order_number) {
        return 'Processando';
      } else {
        return 'Atendido';
      }
    } else if (item.status === 'canceled') {
      return 'Cancelado';
    } else if (item.status === 'sent_to_device') {
      return 'Enviado para força de vendas';
    } else if (item.status === ORDER_STATUS.INVALID_CONTENT) {
      return 'Erro na exportação'
    } else if (isLowPrecisionPDF(item)) {
      return 'Revisão necessária'
    }

    return 'Pendente';
  }

  const getActionsForItem = (item) => {
    const products = item.products || []
    const actionItems = [];

      actionItems.push(
         <IconText type="ordered-list" text={_.filter(products, (product) => {
           return product.quantity > 0
         }).length} key="list-vertical-message" />
      );

      if (item.status !== 'revised' && item.status !== 'sent_to_device' && item.status !== ORDER_STATUS.INVALID_CONTENT) {
          actionItems.push(<IconText type="ordered-list" text={products.length} key="list-vertical-message" />);
          actionItems.push(
          <IconText type="check-circle" color={'#87d068'}
              text={_.filter(products, { 'status': 'resolved' }).length}
              key="list-vertical-star-o" 
            />);
  
          actionItems.push(<IconText type="close-circle" color={'#ff5500'}
              text={_.filter(products, { 'status': 'pending' }).length}
              key="list-vertical-like-o" />);
      }

      if(item.status_erp) {
        actionItems.push(        
          <div style={{display: 'flex', gap: '6px'}}>
                <span style={{
                  fontWeight: '600',
                }}>Status:</span>
                <span style={{
                  fontWeight: '600',
                  color: '#52c41a'
                }}>
                  {item.status_erp}
                </span>
          </div>);
      }
      
      return actionItems;
  }

  const onShowModalChooseCustomer = useCallback((order) => {
    setOrderActive(order);
    setShowModalChooseCustomer(true);
  }, [])

  const handleClickCardOrder = (item) => {
    if(!multiCancel) {
      return;
    }

    onCheckOrder(item);
  }

  const onSaveCancelMultiOrders = () => {
    const ordersIds = Object.entries(checkedOrders)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
    const countOrders = ordersIds.length;

    if(countOrders === 0) {
      return message.warn('Selecione o(s) pedido(s) que deseja remover.');
    }

    Modal.confirm({
      title: 'Cancelar pedido(s)',
      content: `Deseja realmente cancelar o(s) ${countOrders} pedido(s)?`,
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        beforeCancelMulti();
        cancelMultiple(ordersIds)
          .then((response) => {
            message.success('Pedido(s) cancelado(s) com sucesso!');
            onSuccessCancelMulti(response);
          })
          .catch((err) => {
            message.error(`${err.data?.message || 'Não foi possível cancelar o(s) pedido(s), tente novamente mais tarde.'}`);
          })
          .finally(() => {
            onFinnalyCancelMulti()
          });
      }
    })
  }

  return (
    <>
    <Spin spinning={loadingCancelOrders} size='large'>
      {(showMultiCancel && Boolean(data?.length)) && <div style={{ position: 'sticky', top: '60px', background: '#f0f2f5', zIndex: 9, paddingBottom: '6px' }}>
              {!multiCancel ?
                <Button 
                  size="large" 
                  type="link" 
                  style={{paddingLeft: '0px', marginTop: '12px', paddingBottom: '0px'}}
                  onClick={onClickSelectOrders}>
                  Selecionar pedidos
                </Button>
                :
                (
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', paddingTop: '12px'}}>
                    <span>
                      <Button
                        size="large"
                        type="text"
                        style={{marginRight: '8px', width: '18px', height: '24px'}}
                        icon={<CloseOutlined />}
                        onClick={onClickSelectOrders}
                      ></Button>
                      {countCheckedOrders} item(s) selecionado(s)
                    </span>

                    <Checkbox 
                      style={{paddingLeft: '0px'}}
                      checked={allIsChecked}
                      indeterminate={!allIsChecked && countCheckedOrders} 
                      onChange={onCheckAllOrders}
                    > 
                      Marcar todos
                    </Checkbox>
                  </div>
                )}
      </div>}
      <List
        itemLayout="vertical"
        size="large"
        style={{
          marginTop: multiCancel ? marginList : 10,
        }}
        dataSource={data}
        renderItem={item => {
          const document = _.get(item, 'duplicate_reference.document', null);
          const customerNin = _.get(item, 'customer.nin', 'Sem CNPJ');
          const customerName = _.get(item, 'customer.name', 'Sem nome');

          return (
            <div
              style={{ display: 'flex', background: checkedOrders[item.id] ? '#e6e6e6' : '' }} 
              onClick={() => item.status !== 'canceled' ? handleClickCardOrder(item) : null}>
            {multiCancel && <Checkbox
              checked={checkedOrders[item.id]}
              style={{ fontSize: '22px'}} />}
            <List.Item
              key={item.id}
              style={{
                flex: 1,
                padding: 5,
                borderBottom: '1px solid #e6e6e6',
                opacity: (multiCancel && item.status === 'canceled') ? 0.6 : 1,
                cursor: multiCancel ? (item.status === 'canceled' ? 'not-allowed' : 'pointer') : '',
              }}
              actions={getActionsForItem(item)}>
              <List.Item.Meta
                style={{
                  marginBottom: 0,
                }}
                onClick={() => {
                  if(multiCancel) {
                    return
                  }

                  if (item.status !== ORDER_STATUS.CLIENT_NOT_FOUND) {
                    onClickOrder(item);
                  }
                }}
                title={
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'top'
                  }}>
                    {
                      item.reference === 'INEXISTENTE' ?
                        (
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                              {multiCancel ? (<Tag> Sem referência </Tag>) : (
                              <>
                                <Input name={`reference-${item.id}`}
                                  id={`reference-${item.id}`}
                                  placeholder="Código do Pedido" />
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}>
                                  <span
                                    style={{fontSize: 10, color: '#f71414'}}>
                                    Insira o Código do pedido no PDF.
                                  </span>                                
                                  <Button type="primary"
                                    onClick={onSavingOrderNumber.bind(this, item)}
                                    style={{
                                    }}>Confirmar</Button>
                                </div>
                              </>)}
                            </div>
                        )
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '22%', gap: '4px' }}>
                            <Tag>#{item.reference}</Tag>
                            <Tag color={getColor(item)} style={{ color: getContrast(getColor(item)) }}>{getStatusLabel(item)}</Tag>
                        </div>
                    }

                    {
                      item.initial_page ?
                        <span style={{ fontSize: '14px' }}> Página {item.initial_page} </span> : null
                    }

                    {
                      item.pdf_id ?
                        <span style={{ fontSize: '14px' }}>
                          <span style={{ fontWeight: '600' }}>PDF ID </span>{item.pdf_id}
                        </span> : null
                    }

                  </div>
                }
                description={<div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {
                    item.status === ORDER_STATUS.CLIENT_NOT_FOUND ?
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                          <span style={{ fontWeight: '600', fontSize: '16px' }}>Cliente não encontrado</span>
                          {!multiCancel && <TouchableOpacity
                            onPress={onShowModalChooseCustomer.bind(this, item)}
                          >
                            <span style={{ marginRight: 8 }}>
                              <LegacyIcon type={'form'}
                                style={{ marginRight: 4 }} />
                              Selecionar
                            </span>
                          </TouchableOpacity>}
                        </div>

                        <ExtraInfoCustomerNotFound
                          containerStyle={{
                            borderRadius: 8,
                            border: '1px solid #c3c3c3',
                            marginBottom: 5,
                            padding: 5,
                          }}
                          item={item}
                          customer_info={item.customer_info} />

                      </div>
                      :
                      <p style={{ fontWeight: '600', fontSize: '15px' }}>
                        {`${customerNin} - ${customerName}`}
                      </p>
                  }
                  {((item.status === 'viewed' || item.status === 'created' || item.status === ORDER_STATUS.CLIENT_NOT_FOUND
                    || item.status === ORDER_STATUS.INVALID_CONTENT) && !multiCancel) &&
                    <span>
                      <DeleteFilled style={{ fontSize: '20px' }} onClick={showModal.bind(this, item)} />
                    </span>
                  }

                </div>}
              />
              <div>{item.status === 'revised' &&
                (
                  <div>
                    <span style={{
                      fontSize: '14px',
                      color: 'rgba(0, 0, 0, 0.65)',
                      fontWeight: 600
                    }}>Número polibrás: </span>
                    <span>{item.order_number || 'Aguardando'}</span>
                  </div>
                )
              }
              </div>
              {<IconText type="clock-circle" text={moment(item.created_at).format('DD/MM/YYYY HH:mm')}
                key="list-vertical-like-o" />}
              <span style={{
                float: 'right'
              }}> {formatBRL(item.amount || 0)} </span>

              <div>
                <span style={{
                  fontWeight: 600
                }}>Últ. movimentação: </span><span>{moment(item.modified_at).format('DD/MM/YYYY HH:mm')}</span>
              </div>

              {isLowPrecisionPDF(item) && showAlertPrecision && (
                <Alert
                    banner
                    type='warning'
                    style={{
                      marginTop: 5,
                      borderRadius: 10,
                    }}
                    message={
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        O layout deste PDF pode dificultar a identificação correta dos pedidos. Revise-os antes de executar qualquer ação.
                        {showButtonAlertPrecision && <Button 
                            type='primary' 
                            ghost 
                            style={{ width: 'fit-content', marginTop: '4px' }} 
                            onClick={() => history.push(`/documents?t=${btoa(_.get(item, 'pdf_id'))}`)} >
                            Pedidos deste PDF 
                        </Button>}
                      </div> 
                    }
              />
              )}
              {
                (item.status === 'viewed' || item.status === 'created') && document && (
                  <Alert
                    banner
                    style={{
                      marginTop: 5,
                      borderRadius: 10,
                    }}
                    message={
                      <div>
                        Identificamos que em {moment(document.created_at).format('LLL')}, foi enviado um pedido no pdf
                        id: {document.identifier || '#'} com
                        as mesmas características desse, mesmo número do pedido e CNPJ da loja.
                        <br />Isso é apenas um alerta para garantir que você não envie erroneamente o mesmo pedido mais
                        de uma vez.
                      </div>
                    }
                  />
                )
              }
                {
                (item.status === 'viewed' || item.status === 'created') && item?.seller?.code !== profile[0].seller.login &&
                  <Alert
                    banner
                    style={{
                      marginTop: 5,
                      borderRadius: 10,
                    }}
                  message={
                    <div>Este pedido é para o vendedor {item?.seller?.code ?? '-/-'} e ele também poderá editá-lo.
                    </div>
                    }
                  />
              }
              {
                (item.status === ORDER_STATUS.INVALID_CONTENT) && (
                  <Alert
                    banner
                    style={{
                      marginTop: 5,
                      borderRadius: 10,
                    }}
                    message={
                      <div>
                        {item.invalid_reason}
                      </div>
                    }
                  />
                )
              }
              
            </List.Item>
            </div>
          );
        }}
      >
        {loading && (
          <div className="demo-loading-container">
            <Spin size='large' style={{ position: 'fixed', zIndex: 10, top: '125px', left: '50%' }} />
          </div>
        )}
      </List>

      {
        orderActive && (
          <ModalChooseCustomer
            reloadListOrders={onRefreshList}
            visible={showModalChooseCustomer}
            order={orderActive}
            suggestions={[{}, {}]}
            hasSearch={true}
            onCancel={() => {
              setShowModalChooseCustomer(false);
            }}
          />
        )
      }

      {(multiCancel && !hideConfirmButton) && <div style={{  display: 'flex' }}>  
        <Button
          size="large"
          type="primary" 
          disabled={loadingCancelOrders}
          style={{ bottom: '0px', left: '0px', position: 'fixed', width: '100%', zIndex: 10 }} 
          onClick={onSaveCancelMultiOrders}
          >
            Cancelar pedidos selecionados
        </Button>
      </div>}
      </Spin>
    </>
  )
}

export default withRouter(OrdersList)
