import React, { Component } from 'react';
import _ from 'lodash';
import {
  CheckCircleFilled,
  DeleteFilled,
  ExclamationCircleFilled,
  FileSearchOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Img } from 'react-image';
import { Alert, Button, Checkbox, Input, List, Modal, Select, Spin } from 'antd';
import { formatMoneyBRL, isLowPrecision } from '../Util'
import Tooltip from '../Components/Tooltip';
import ImageLoader from 'react-load-image'
import { formatBRL } from '../Util/Number'
import { URL_BASE } from "../Services";
import ModalProductSelect from "./ModalProductSelect";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

const styles = {
  flexDirectionRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    gap: '8px'
  },
  flexDirectionColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconSuggestions: {
    fontSize: 24,
    marginTop: 15,
  },
  productName: {
    fontWeight: '700',
    lineHeight: '22px',
    wordWrap: 'break-word'
  },
  disabledProductName: {
    fontWeight: '600',
    wordWrap: 'break-word',
    textDecoration: 'line-through'
  },
  productCode: {
    fontWeight: '500',
    marginRight: 5,
  },
  productError: {
    fontStyle: 'italic',
    marginTop: '12px'
  },
  inputQuantity: {
    width: 60,
    marginLeft: 5
  }
}

function loaded() {
  console.log('DATA');
}

class ListProducts extends Component {

  constructor(props) {
    super(props)

    this.state = {
      amount: 0,
      images: {},
      open_modal_version: false,
      id_image: '',
      open_modal_advanced_search: false,
      itemCurrent: null,
      prodsRemove: [],
    }
  }

  setOpenModalVersion = async (open, id, title) => {
    if(this.props.multiRemoveProds) {
      return;
    }

    this.setState({
      open_modal_version: open,
      id_image: id,
      title,
    });
  }

  setOpenModalAdvancedSearch = () => {

    console.log('setOpenModalAdvancedSearch', this.state.open_modal_advanced_search);

    this.setState({
      open_modal_advanced_search: true,
    });
  }

  onChange = (e, item) => {
    const { value } = e.target
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.changeQty(item, value)
    }
  }

  onClickDelete = (item) => {
    this.props.changeQty(item, 0)
    if (!item.product_code) {
      this.props.handleChangeCode(item, '0')
    }
  }

  buttonDelete = (item) => {
    return (
      <DeleteFilled
        style={{ fontSize: '18px', }}
        onClick={this.onClickDelete.bind(this, item)} />
    );
  }

  handleCheckProduct = (item) => {
    if(!this.props.multiRemoveProds) {
      return;
    }

    const { index } = item || {};
    const isCheck = this.props.checkedItems[index] || false;

    this.props.onChangeProductsToRemove(item, isCheck ? 'remove' : 'add');
  }

  checkDelete = (item, e: CheckboxChangeEven) => {
    const { index } = item || {};
    const isChecked = this.props.checkedItems[index] || false;
    return (
      <Checkbox
        checked={isChecked}
        style={{ fontSize: '22px', }}
        />
    );
  }

  statusItem = (item) => {
    if ((item.quantity || item.quantity === 0) && item.product_code && (item.unit_value || item.unit_value === 0)) {
      return <CheckCircleFilled style={{ fontSize: '18px', color: '#40b506' }} />;
    } else if (!item.quantity || item.product_code || item.unit_value) {
      return <ExclamationCircleFilled style={{ fontSize: '18px', color: '#f71414', }} />;
    } else {
      return <ExclamationCircleFilled style={{ fontSize: '18px', color: '#40b506' }} />;
    }
  }

  shouldShowLowPrecisionIcon = (item, original_item) => {
    const precisionFields = [
        item.name_precision,
        item.internal_code_precision,
        original_item.precisao_cod_cliente,
        original_item.precisao_ean,
        item.unit_value_precision,
        item.total_value_precision,
        original_item.precisao_unidade,
        item.quantity_precision
    ];

    return precisionFields.some(isLowPrecision);
};

  lowPrecisionTextNotification = (item, original_item) => {
    const precisionFields = [
        { key: item.name_precision, text: "o Nome do Produto" },
        { key: item.internal_code_precision, text: "a Referência" },
        { key: original_item.precisao_cod_cliente, text: "a Referência do Cliente" },
        { key: original_item.precisao_ean, text: "o EAN" },
        { key: item.unit_value_precision, text: "o Preço Unitário" },
        { key: item.total_value_precision, text: "o Valor Total" },
        { key: original_item.precisao_unidade, text: "a Unidade da Embalagem" },
        { key: item.quantity_precision, text: "a Quantidade" }
    ];

    const stringList = precisionFields
        .filter(field => isLowPrecision(field.key))
        .map(field => field.text);

    if (stringList.length === 0) return null;
    
    const formatList = (list) => {
        if (list.length === 1) return list[0];
        if (list.length === 2) return list.join(" e ");
        return list.slice(0, -1).join(", ") + " e " + list[list.length - 1];
    };

    return `Revise ${formatList(stringList)} para seguir no envio do pedido.`;
  };

  setLowPrecisionStyle = (precision) => {
    if (isLowPrecision(precision)) {
      return 'low-precision';
    }

    return '';
  }

  isUndefOrNullValue = (value) => {
    if (value === undefined || value === null) {
      return true; 
    }

    return false;
  }

  render() {
    const { original_items, items, visual_decimal_places, duplicates, status } = this.props;
    let items_sorted = [];
    if (duplicates.length && status === 'all') {
      items_sorted = _.sortBy(items, ['duplicate', 'product_code']);
    } else {
      items_sorted = _.sortBy(items, ['index']);
    }

    return (
      <div>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={items_sorted}
          renderItem={(item) => {
            const { index, message, allow_search, preco_maximo, preco_minimo, d_estoque } = item;
            const original_item = original_items[index - 1];
            const num_page = original_item.num_page + 1;
            return (
              <List.Item 
                onClick={() => this.handleCheckProduct(item)}
                key={index}
                style={{
                  background: this.props.checkedItems[index] && this.props.multiRemoveProds ? '#e6e6e6' : '',
                  cursor: this.props.multiRemoveProds ? 'pointer': '',
                  paddingLeft: 10
                }}>
                <div style={styles.flexDirectionRow}>
                  {this.props.multiRemoveProds && <span>{this.checkDelete(item)}</span>}
                  <div style={styles.flexDirectionColumn}>
                    <div style={{
                      width: '80px',
                      height: '130px',
                    }}>
                      <ImageLoader src={item.image}>
                        <img alt='' style={{
                          width: '80px',
                          height: '130px',
                        }} />
                        <img src='../../assets/no-image.png' alt='' style={{
                          width: '80px',
                          height: '130px',
                        }} />
                      </ImageLoader>
                    </div>
                    {!this.props.multiRemoveProds && <FileSearchOutlined onClick={() => {
                      this.setState({
                        itemCurrent: { ...item }
                      }, () => {
                        this.setOpenModalAdvancedSearch();
                      })
                    }} style={styles.iconSuggestions} />}
                  </div>
                  <div style={{
                    marginLeft: 10,
                    flex: 1,
                  }}>
                    <div style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      maxHeight: '22px',
                    }}>
                      <div className="item-group low-precision-icon">
                        <span
                          className={this.setLowPrecisionStyle(item.name_precision)} 
                          style={{
                            ...(item.quantity > 0 ? styles.productName : styles.disabledProductName)
                          }}
                          onClick={() => {
                            this.setOpenModalVersion(true, original_item.id_img_descricao, 'Descrição')
                          }}>
                          {item.name}

                          </span> 
                          
                          <span>
                            <Tooltip text={"Vimos que as informações deste produto podem estar incorretas. " 
                            + this.lowPrecisionTextNotification(item, original_item)}>
                            {this.shouldShowLowPrecisionIcon(item, original_item) && (
                                <ExclamationCircleFilled style={{ fontSize: '18px', color: '#f71414', }}/>
                            )}
                            </Tooltip>  
                            </span>
                      </div>
                      {!this.props.multiRemoveProds && <span>{this.buttonDelete(item)}</span>}
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                      <span style={{
                        marginRight: 10,
                      }}>Pág. {num_page} </span>
                      <span>Item {original_item.linha}</span>
                    </div>
                    <div style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                      <div style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }} onClick={() => {
                        this.setOpenModalVersion(true, original_item.id_img_cod_fornecedor, 'Código Fornecedor')
                      }}>
                        <span style={{
                          fontWeight: '500',
                          marginRight: 5,
                        }}>REFERÊNCIA: </span><span
                          className={this.setLowPrecisionStyle(item.internal_code_precision)}>
                          {item.internal_code}
                        </span>
                        
                      </div>
                      
                    </div>
                    
                    <div style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                      <div style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }}>
                        <span style={{
                          fontWeight: '500',
                          marginRight: 5,
                        }} onClick={() => {
                          this.setOpenModalVersion(true, original_item.id_img_cod_cliente, 'Referência do cliente')
                        }}>REF. CLIENTE: </span><span
                          onClick={() => {
                            this.setOpenModalVersion(true, original_item.id_img_cod_cliente, 'Referência do cliente')
                          }}
                          className={this.setLowPrecisionStyle(original_item.precisao_cod_cliente)}>
                          {original_item.cod_cliente}
                        </span>

                      </div>
                    </div>
                   
                    <div style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}>
                      <span style={{
                        fontWeight: '500',
                        marginRight: 5,
                      }} onClick={() => {
                        this.setOpenModalVersion(true, original_item.id_img_ean, 'EAN')
                      }}>EAN:</span><span
                        onClick={() => {
                          this.setOpenModalVersion(true, original_item.id_img_ean, 'EAN')
                        }}
                        className={this.setLowPrecisionStyle(original_item.precisao_ean)}>
                      {item.ean} </span>
                      
                    </div>
                    {!this.isUndefOrNullValue(d_estoque) && <div style={styles.flexDirectionRow}>
                      <label style={{ fontWeight: '500', marginBottom: 0 }} >
                        ESTOQUE: 
                      </label>
                      <span style={{...(!d_estoque && ({ color: '#f71414' }))}}>
                        {d_estoque || 0}
                      </span>
                    </div>}
                    {!this.isUndefOrNullValue(preco_minimo) && !this.isUndefOrNullValue(preco_maximo) && <div style={styles.flexDirectionRow}>
                      <label style={{ fontWeight: '500', marginBottom: 0 }} >
                        PREÇO CADASTRADO: 
                      </label>
                      <span>
                        {preco_maximo === preco_minimo ? `${formatMoneyBRL(preco_maximo)}`: `${formatMoneyBRL(preco_minimo)} ~ ${formatMoneyBRL(preco_maximo)}`}   
                      </span>
                    </div>}
                    <div style={{
                      ...styles.flexDirectionRow,
                      alignItems: 'center',
                    }}>
                      <span style={styles.productCode}>CÓDIGO: </span>
                      {this.props.multiRemoveProds ? (
                        <span>{item.product_code}</span>) : (
                        <Input name={`code-${index}`}
                        id={`code-${index}`}
                        style={{ maxWidth: item.product_code && item.product_code.length > 12 ? '240px' : '120px' }}
                        className={!item.product_code ? 'low-precision' : ''}
                        ref={(input) => {
                          this[`code-${index}`] = input
                        }}
                        onChange={(e) => {
                          const { value } = e.target
                          this.props.handleChangeCode(item, value)
                        }}
                        value={item.product_code}
                        defaultValue={item.product_code} />)}
                    </div>

                    <div style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}>
                        <span className={!item.product_code ? 'low-precision' : ''}
                          style={{marginLeft: 72, fontSize: 10}}>
                          {!item.product_code ? "Campo obrigatório." : null}
                          </span>
                    </div>                

                    <div style={styles.flexDirectionRow}>
                      <div style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column'
                      }}>
                        <label onClick={() => {
                          this.setOpenModalVersion(true, original_item.id_img_vl_unitario, 'Valor Unitário')
                        }}
                          style={{
                            fontWeight: '500',
                          }}>PREÇO: </label>

                        {this.props.multiRemoveProds ? (<span>{item.unit_value}</span>) : (
                          <Input 
                              name={`price-${index}`}
                              id={`price-${index}`}
                              className={this.setLowPrecisionStyle(item.unit_value_precision)}
                              prefix={'R$ '}
                              type={'number'}
                              value={item.unit_value}
                              onChange={(e) => {
                                this.props.handleChangeAmount(item, e.target.value)
                              }}
                              onBlur={() => {
                                this.props.onBlurAmount(item);
                              }}
                              defaultValue={item.unit_value}
                              style={{
                                width: 130
                              }} 
                          />
                        )}
                        
                      </div>

                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                        <span 
                          onClick={() => {
                          this.setOpenModalVersion(true, original_item.id_img_unidade, 'Embalagem')
                        }}>Emb: 
                          <span className={this.setLowPrecisionStyle(original_item.precisao_unidade)}>
                            {original_item.unidade}
                          </span>
                        </span>
                        <div style={{
                          ...styles.flexDirectionRow,
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end'
                        }}>
                          {!this.props.multiRemoveProds && (
                            <>
                            <Button 
                              type="primary" 
                              shape="circle" 
                              icon={<MinusOutlined />}
                              onClick={() => {
                                this.props.handleChangeQtyMinus(item)
                              }} 
                            />
                            <Input 
                              name={`quantity-${index}`}
                              id={`quantity-${index}`}
                              className={this.setLowPrecisionStyle(item.quantity_precision)}
                              type={'tel'}
                              value={item.quantity}
                              onChange={(e) => {
                                this.onChange(e, item)
                              }}
                              defaultValue={item.quantity}
                              style={styles.inputQuantity} />

                            <Button 
                              type="primary"
                              shape="circle"
                              icon={<PlusOutlined />}
                              style={{ marginLeft: 5 }}
                              onClick={() => {
                                this.props.handleChangeQtyPlus(item)
                              }} />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={{
                      ...styles.flexDirectionRow,
                      justifyContent: 'space-between',
                    }}>
                      <span onClick={() => {
                        this.setOpenModalVersion(true, original_item.id_img_vl_total, 'Valor Total')
                      }}>
                        <span style={{
                          fontWeight: '500',
                        }}>TOTAL: </span> 
                        <span className={this.setLowPrecisionStyle(item.total_value_precision)}>
                          {formatBRL(item.total_value, visual_decimal_places)}
                        </span>
                      </span>

                      <span style={{
                        marginRight: this.props.multiRemoveProds ? 10 : 55
                      }}
                        onClick={() => {
                          this.setOpenModalVersion(true, original_item.id_img_qtd, 'Quantidade')
                        }}>
                        <span style={{
                          fontWeight: '500',
                        }}>QTD {this.props.multiRemoveProds ? `: ${item.quantity}` : ''} </span>
                      </span>
                    </div>

                    {
                      !!item.error_message && !item.message ?
                        <div style={styles.flexDirectionRow}>
                          <span style={styles.productError}>
                            <Alert message={item.error_message} type="error" showIcon />
                          </span>
                        </div> : null
                    }

                    {
                      !!message &&
                      <div style={styles.flexDirectionRow}>
                        <span style={styles.productError}>
                          <Alert message={message.message} type={message.kind} showIcon />
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </List.Item>
            );
          }}
        />

        {
          this.state.open_modal_advanced_search && this.state.itemCurrent && (
            <ModalProductSelect
              itemCurrent={this.state.itemCurrent}
              onChangeProductSuggest={this.props.onChangeProductSuggest}
              visible={this.state.open_modal_advanced_search}
              suggestions={this.state.itemCurrent.suggestions}
              order={this.props.order}
              onCancel={() => {
                this.setState({
                  open_modal_advanced_search: false,
                });
              }}
            />
          )
        }

        {
          this.state.open_modal_version && (
            <Modal
              title={'Imagem ' + (this.state.title || '')}
              footer={null}
              visible={this.state.open_modal_version}
              onCancel={() => {
                this.setOpenModalVersion(false)
              }}
            >
              {
                this.state.id_image && (
                  <Img
                    src={[URL_BASE + '/cell_image/?img_id=' + this.state.id_image]}
                    style={{
                      maxWidth: '100%',
                    }}
                    loader={
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                        <Spin />
                      </div>
                    }
                  />
                )
              }
            </Modal>
          )
        }
      </div>
    );
  }
}

export default ListProducts;
